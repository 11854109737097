import React, { useMemo, useState } from 'react'
import { Paper, TableContainer, TableHead, TableRow, Table as MuiTable, TableCell, TableBody, Grid } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { darkTextColor, mainColor, menuColor, textColor } from './styled/theme'
import { groupBy, orderBy } from 'lodash'
import { getDate } from '../../../utils/DateUtil'

const ASC = 'asc'
const DESC = 'desc'

const Table = ({
    grouped,
    rowClickable,
    sortable,
    transparent,
    inverted,
    accordion,

    rows = [],
    headers = [],
    headersLabel = {},

    sx = {},
    headerColor = mainColor,
    hoverColor= menuColor,
    bodyColor = 'transparent',

    onClickRow,

    defaultSort = { sortColumn: [], sortDirection: {} },

    WrapperComponent = Paper,
}) => {
    const [sort, setSort] = useState(defaultSort)
    const [expanded, setExpanded] = useState(false)

    const sortedRows = useMemo(() => {
        const {
            sortColumn,
            sortDirection,
        } = sort
        return orderBy(rows, sortColumn.map(c => elem => elem[c]?.sortValue ?? elem[c]?.value), sortColumn.map(c => sortDirection[c]))
    }, [rows, sort])

    const handleSort = (header) => {
        setSort(({ sortDirection, sortColumn }) => {
            switch (sortDirection[header]) {
                case ASC: return { sortDirection: { ...sortDirection, [header]: DESC }, sortColumn }
                case DESC: return { sortDirection: { ...sortDirection, [header]: undefined }, sortColumn: sortColumn.filter(c => c !== header) }
                default: return { sortDirection: { ...sortDirection, [header]: ASC }, sortColumn: [...sortColumn, header] }
            }
        })
    }

    const supHeaderStyle = (isActive) => {
        return isActive ? {
            backgroundColor: 'white',
            color: textColor
        } : null
    }

    const startGroupStyle = (isActive) => {
        return isActive ? {
            paddingRight: '0',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',

        } : null
    }
    const midGroupStyle = (isActive) => {
        return isActive ? {
            padding: '0',
        } : null
    }

    const endGroupStyle = (isActive) => {
        return isActive ? {
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
        } : null
    }

    const radius = (isFirstGroupCell, isMidGroupCell, isLastGroupCell) => {
        if (isFirstGroupCell) {
            return {
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
            }
        }
        if (isMidGroupCell) {
            return {}
        }
        if (isLastGroupCell) {
            return {
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
            }
        }
        return { borderRadius: '5px' }
    }

    const groupedHeaders = headers.map(header => groupBy(header, 'group'))

    return (
        <WrapperComponent style={{ boxShadow: 'none' }} sx={{ width: '100%', backgroundColor: 'transparent', ...sx }}>
            <TableContainer >
                <MuiTable stickyHeader size='small' aria-label='table'>
                    <TableHead>
                        {
                            grouped ? (
                                groupedHeaders.map((header, i) => (
                                    <TableRow
                                        key={i}
                                    >
                                        {accordion ? <TableCell colSpan={1} key={0}/> : null}
                                        {
                                            Object.values(header).map((group) => group.map((col, j) => {
                                                const groupSize = group?.length || 0
                                                const isFirstGroupCell = groupSize > 1 && col === group[0]
                                                const isMidGroupCell = groupSize > 1 && col !== group[0] && col !== group[groupSize - 1]
                                                const isLastGroupCell = groupSize > 1 && col === group[groupSize - 1]

                                                return (
                                                    <TableCell
                                                        variant='head'
                                                        key={j + 1}
                                                        colSpan={col.colSpan}
                                                        container
                                                        className={sortable ? 'clickable' : ''}
                                                        sx={{
                                                            paddingRight: '8px',
                                                            ...startGroupStyle(isFirstGroupCell),
                                                            ...midGroupStyle(isMidGroupCell),
                                                            ...endGroupStyle(isLastGroupCell),
                                                        }}
                                                        onClick={() => sortable ? handleSort(header) : {}}
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems= 'center'
                                                            sx={{
                                                                textWrap: 'nowrap',
                                                                height: '100%',
                                                                fontWeight: 'medium',
                                                                padding: '8px 16px',
                                                                backgroundColor: transparent ? 'transparent' : (col.value ? headerColor : 'transparent'),
                                                                color: transparent ? headerColor : 'white',
                                                                ...radius(isFirstGroupCell, isMidGroupCell, isLastGroupCell),
                                                                ...supHeaderStyle(inverted ? i !== 0 : i < headers.length-1),
                                                            }}
                                                        >
                                                            {col.value}
                                                        </Grid>
                                                    </TableCell>
                                                )
                                            }))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                headers.map((header, i) =>(
                                    <TableRow key={i}>
                                        {accordion ? <TableCell key={0}/> : null}
                                        {
                                            header.map((col, j) => (
                                                <TableCell
                                                    variant='head'
                                                    key={j + 1}
                                                    colSpan={col.colSpan}
                                                    container
                                                    className={sortable ? 'clickable' : ''}
                                                    sx={{
                                                        paddingRight: '8px',
                                                    }}
                                                    onClick={() => sortable ? handleSort(col.value) : {}}
                                                >
                                                    <Grid container
                                                        alignItems= 'center'
                                                        sx={{
                                                            textWrap: 'nowrap',
                                                            height: '100%',
                                                            fontWeight: 'medium',
                                                            padding: '8px 16px',
                                                            backgroundColor: transparent ? 'transparent': (col.value ? headerColor : 'transparent'),
                                                            color: transparent ? headerColor : 'white',
                                                            borderRadius: '5px',
                                                            ...supHeaderStyle(inverted ? i !== 0 : i < headers.length-1),
                                                        }}>
                                                        <Grid container alignItems='center'>
                                                            {sort?.sortColumn?.includes(col.value) && <span style={{ fontSize: 12 }}>{sort.sortColumn.indexOf(col.value) + 1}</span>}
                                                            {
                                                                sort.sortDirection[col.value] === ASC && <Grid item><KeyboardArrowUp fontSize='14px' /></Grid>
                                                            }
                                                            {
                                                                sort.sortDirection[col.value] === DESC && <Grid item><KeyboardArrowDown fontSize='14px' /></Grid>
                                                            }
                                                            {col.value}
                                                        </Grid>

                                                    </Grid>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                )))
                        }
                    </TableHead>
                    <TableBody>
                        {
                            sortedRows.map((row, ir) => (
                                <>
                                    <TableRow
                                        key={ir}
                                        className={rowClickable ? 'clickable' : ''}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: rowClickable && hoverColor
                                            }
                                        }}
                                    >
                                        {accordion ?
                                            <TableCell>
                                                <KeyboardArrowDown
                                                    className='clickable'
                                                    style={{
                                                        rotate: expanded === ir ? '180deg' : '0deg',
                                                        transition: 'all 0.2s ease-in-out'
                                                    }}
                                                    onClick={expanded === ir ? () => setExpanded(false) : () => setExpanded(ir)}
                                                />
                                            </TableCell>
                                            :
                                            null}
                                        {
                                            headersLabel.map((header, i) => {
                                                const {
                                                    value = '-',
                                                    align = 'left',
                                                    backgroundColor = bodyColor,
                                                    color = darkTextColor,
                                                    padding = '0',
                                                    textWrap= 'wrap',
                                                    toDate= false,
                                                } = row[header] ?? {}
                                                return (
                                                    <TableCell
                                                        key={i}
                                                        variant='body'
                                                        onClick={rowClickable ? () => onClickRow(row) : () => {}}
                                                        sx={{
                                                            backgroundColor,
                                                            width: 'fit-content',
                                                        }}
                                                    >
                                                        <div style={{ textAlign: align, color, padding, textWrap }}>
                                                            {toDate ? getDate(value) : value}
                                                        </div>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                    {
                                        accordion && expanded === ir ?
                                            row.body?.map((rowBody, ibr) => (
                                                <TableRow
                                                    key={ibr}
                                                    style={{
                                                        transition: 'all 0.2s cubic-bezier'
                                                    }}
                                                >
                                                    <TableCell />
                                                    {
                                                        headersLabel.map((header, i) => {
                                                            const {
                                                                value = '',
                                                                align = 'left',
                                                                backgroundColor = bodyColor,
                                                                color = darkTextColor,
                                                                padding = '0',
                                                                textWrap= 'wrap',
                                                                toDate= false,
                                                            } = rowBody[header] ?? {}
                                                            return (
                                                                <TableCell
                                                                    key={i}
                                                                    variant='body'
                                                                    sx={{
                                                                        backgroundColor,
                                                                        width: 'fit-content',
                                                                    }}
                                                                >
                                                                    <div style={{ textAlign: align, color, padding, textWrap }}>
                                                                        {toDate ? getDate(value) : value}
                                                                    </div>
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            ))
                                            :
                                            null
                                    }
                                </>
                            ))
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </WrapperComponent>
    )
}

Table.propTypes = {
    grouped: PropTypes.bool,
    rowClickable: PropTypes.bool,
    sortable: PropTypes.bool,
    transparent: PropTypes.bool,
    inverted: PropTypes.bool,
    accordion: PropTypes.bool,

    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))).isRequired,
    headersLabel: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])).isRequired,

    sx: PropTypes.arrayOf(PropTypes.object),
    headerColor: PropTypes.string,
    hoverColor: PropTypes.string,
    bodyColor: PropTypes.string,

    onClickRow: PropTypes.func,

    defaultSort: PropTypes.shape({
        sortColumn: PropTypes.arrayOf(PropTypes.string),
        sortDirection: PropTypes.objectOf(PropTypes.string),
    }),

    WrapperComponent: PropTypes.elementType,
}

export default Table