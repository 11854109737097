/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { Close, Done, EditOutlined } from '@mui/icons-material'
import { LightCard } from '../styled/grid'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'
import LoadingCard from './LoadingCard'
import { DECLARATION_LINK_TYPE, MAT_CATEGORY } from '../../agri/constants/AgriConstants'
import { InputRow } from '../styled/inputs'
import { hasValue } from '../../../../utils/NumberUtil'
import MaterielAction from '../../referencials/materiels/actions/MaterielAction'
import AgriAction from '../../agri/actions/AgriAction'
import moment from 'moment'
import { useParams } from 'react-router'

const CounterCardDecla = ({ counter, noLightCard, sx }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [updatedCounter, setUpdatedCounter] = useState(counter)
    const [editMode, setEditMode] = useState(false)

    const params = useParams()

    const {
        variousMatTypes,
        codesSandre,
        declaration,
        variousMateriels,
        exploitation,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation
    }), shallowEqual)

    const dispatch = useDispatch()

    const matType = useMemo(() => variousMatTypes.find((t) => t.materielType === updatedCounter.materielType), [updatedCounter])
    const counterCountingTypes = useMemo(() => codesSandre.filter((c) => c.field === 'COMPTAGES.TYPE'))
    const counterCountingType = useMemo(() => counterCountingTypes.find((c) => c.code === updatedCounter.mobilityCode), [updatedCounter])

    const getRepartition = useMemo(() => (
        declaration.link_declarationInstallation.find(instal => instal.idInstallation === Number(params.id))
            ?.link_equipments.find(link => link.idMatAttachment === updatedCounter.id)?.assignementRate
        ??
        exploitation.link_repartition_materiels.find(link =>
            link.linkType === DECLARATION_LINK_TYPE.COUNTER_PUMP && link.idElement1 === Number(params.id)
        )?.repartition
    ), [declaration, updatedCounter])

    /* const getIdCounter = useMemo(() => (
        compact([
            ...declaration.link_declarationInstallation.find(instal => instal.idInstallation === Number(params.id))
                ?.link_equipments.filter(link => link.idMatAttachment === updatedCounter.id).map(link => link.idMat),
        ])
    ), [variousMateriels]) */

    const updateCounter = () => {
        setIsLoading(true)
        dispatch(MaterielAction.updateVariousMateriel({ ...variousMateriels.find(m => m.id === updatedCounter.id), name: updatedCounter.name }, () => {
            dispatch(AgriAction.updateDeclaration({
                ...declaration,
                link_counters: [
                    ...declaration.link_counters.filter(p => p.id !== updatedCounter.id),
                    {
                        ...updatedCounter,
                        idExploitation: declaration.idExploitation,
                        idSurvey: declaration.idSurvey,
                        idInstallation: Number(params.id),
                    }
                ]
            })).then(() => {
                setIsLoading(false)
            })
        }))
    }

    const content = () => {
        if (isLoading) {
            return <LoadingCard />
        }
        return (
            <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                <Grid container rowGap='1vh'>
                    <Grid item container justifyContent='space-between' alignItems='center'>
                        <Grid item sx={{ fontSize: '18px' }}><b>{i18n.counter}</b><br/>{updatedCounter?.name} {updatedCounter?.reference}</Grid>
                        <EditOutlined className='clickable' onClick={() => setEditMode(true)}/>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.type}</Grid>
                        <Grid item className='bold'>{matType?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.countingType}</Grid>
                        <Grid item className='bold'>{counterCountingType?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.marque}</Grid>
                        <Grid item className='bold'>{updatedCounter?.brand ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.reference}</Grid>
                        <Grid item className='bold'>{updatedCounter?.serialNumber ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.coefLecture}</Grid>
                        <Grid item className='bold'>{updatedCounter?.readingCoefficient ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.installationDate}</Grid>
                        <Grid item className='bold'>{updatedCounter?.installationDate ? moment(updatedCounter.installationDate).format('DD/MM/YYYY') : '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.decommisionDate}</Grid>
                        <Grid item className='bold'>{updatedCounter?.revisionDate ? moment(updatedCounter.revisionDate).format('DD/MM/YYYY') : '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.sharedEquipment}</Grid>
                        <Grid item className='bold'>{updatedCounter?.isShared ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.forRent}</Grid>
                        <Grid item className='bold'>{updatedCounter?.rent ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.affectedToPump}</Grid>
                        <Grid item className='bold'>-</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.tauxAffectation}</Grid>
                        <Grid item className='bold'>{getRepartition ? `${getRepartition}%` : '-'}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const editableContent = () => (
        <Grid container sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', rowGap: '1.5vh' }}>
            <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item sx={{ fontSize: '18px' }}><b>{i18n.counter}</b> {updatedCounter?.reference}</Grid>
                <Grid item>
                    <Close className='clickable'
                        onClick={() => {
                            setEditMode(false)
                            setUpdatedCounter(counter)
                        }}
                    />
                    <Done className='clickable' onClick={() => {
                        setEditMode(false)
                        updateCounter()
                    }} />
                </Grid>
            </Grid>
            <InputRow
                label={i18n.name}
                value={updatedCounter.name}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    name: e.target.value
                })}
            />
            <FormControl fullWidth>
                <InputLabel id={i18n.type}>{i18n.type}</InputLabel>
                <Select
                    labelId={i18n.type}
                    label={i18n.type}
                    value={updatedCounter.materielType}
                    onChange={(e) => setUpdatedCounter({ ...updatedCounter, materielType: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        variousMatTypes.map(type => type.category === MAT_CATEGORY.COUNTER && (
                            <MenuItem
                                value={type.materielType}
                                style={{ maxWidth: '100%' }}
                            >
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <InputRow
                label={i18n.marque}
                value={updatedCounter.brand}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    brand: e.target.value
                })}
            />
            <InputRow
                label={i18n.reference}
                value={updatedCounter.serialNumber}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    serialNumber: e.target.value
                })}
            />
            <InputRow
                type='Number'
                label={i18n.coefLecture}
                value={updatedCounter.readingCoefficient}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    readingCoefficient: Number(e.target.value)
                })}
            />
            <Grid container item xs={12} justifyContent='space-between'>
                <Grid item xs={5.9}>
                    <InputRow
                        type='date'
                        label={i18n.installationDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={moment(updatedCounter?.installationDate).format('YYYY-MM-DD')}
                        onChange={(e) => setUpdatedCounter({
                            ...updatedCounter,
                            installationDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                        })}
                    />
                </Grid>
                <Grid item xs={5.9}>
                    <InputRow
                        type='date'
                        label={i18n.revisionDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={moment(updatedCounter?.revisionDate).format('YYYY-MM-DD')}
                        onChange={(e) =>
                            setUpdatedCounter({
                                ...updatedCounter,
                                revisionDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                            })
                        }
                    />
                </Grid>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={updatedCounter?.isShared || null}
                            checked={updatedCounter.isShared}
                            onChange={() => setUpdatedCounter({ ...updatedCounter, isShared: !updatedCounter.isShared })}
                        />
                    }
                    label={i18n.sharedEquipment}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            value={updatedCounter?.rent || null}
                            checked={updatedCounter.rent}
                            onChange={() => setUpdatedCounter({ ...updatedCounter, rent: !updatedCounter.rent })}
                        />
                    }
                    label={i18n.forRent}
                />
            </Grid>
        </Grid>
    )

    if (noLightCard) {
        return editMode ? editableContent() : content()
    }

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            {editMode ? editableContent() : content()}
        </LightCard>
    )
}

CounterCardDecla.propTypes = {
    counter: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    stateCounter: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    isLoading: PropTypes.bool.isRequired,
    setCounter: PropTypes.func.isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
    onClick: PropTypes.func,
    hideIcon: PropTypes.bool.isRequired,
}

export default CounterCardDecla
