/* eslint-disable no-console */
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Routes, Route, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AuthRoute from '../src/pages/online/AuthRoute'
import AppStore, { history } from './store/AppStore'
import fr from './ressources/i18n/properties_fr.json'
// import en from './ressources/i18n/properties_en.json'
import Login from './pages/offline/Login'
import AppDesktop from './pages/online/components/AppDesktop'
import MessagesApp from './pages/online/contact/MessagesApp'
import { darkTextColor, mainBlue, secondaryColor, veryLightGrey } from './pages/online/components/styled/theme'
import Password from './pages/offline/Password'
import Admin from './pages/offline/Admin'
import IndexMaterielsApp from './pages/online/consommation/IndexMaterielsApp'
import EventsMaterielsApp from './pages/online/consommation/EventsMaterielsApp'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import SieauTooltip from './pages/online/components/styled/SieauTooltip'
import HomeDesktop from './pages/online/home/HomeDesktop'
import PointsListAppDesktop from './pages/online/consommation/PointsListAppDesktop'
import MyExploitationDesktop from './pages/online/exploitation/MyExploitationDesktop'
import PointDetailsExploitDesktop from './pages/online/exploitation/PointDetailsExploitDesktop'
import MyAccountDesktop from './pages/online/account/MyAccountDesktop'
import AboutDesktop from './pages/AboutDesktop'
import PointDashboardDesktop from './pages/online/consommation/PointDashboardDesktop'
import HomeSurvey from './pages/online/enquete/HomeSurvey'
import Declaration from './pages/online/enquete/Declaration'
import PointDetailsDeclaDesktop from './pages/online/enquete/components/PointDetailsDeclaDesktop'
import PointUsagesPrevi from './pages/online/enquete/components/PointUsagesPrevi'
import TutorielDesktop from './pages/TutorielDesktop'
import IndexMaterielsAppDecla from './pages/online/enquete/components/IndexMaterielsAppDecla'
import MyDocumentsDesktop from './pages/online/documents/MyDocumentsDesktop'
import ComponentWithNavObject from './utils/ComponentWithNavObject'
import { ReduxRouter } from '@lagunovsky/redux-react-router'

i18n.addLang(fr, ['fr', 'FR'])
// i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

const theme = createTheme({
    typography: {
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: mainBlue,
        },
        secondary: {
            main: secondaryColor,
        },
        action: {
            disabledBackground: veryLightGrey,
            disabled: 'white',
        },
    },
    components: {
        MuiTable: {
            defaultProps: {
                'aria-label': 'table',
                border: 0,
                backgroundColor: 'transparent'
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${veryLightGrey}`,
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: 'transparent'
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    backgroundColor: 'transparent'
                },
                head: {
                    fontWeight: 'bold',
                    height: '50px',
                    textWrap: 'wrap',
                    borderBottom: 0,
                    textAlign: 'center',
                    padding: '0',
                    '&:last-child': { paddingRight: '0' },
                },
                body: {
                    padding: '16px 8px',
                    backgroundColor: 'transparent',
                    color: darkTextColor,
                    textAlign: 'start',
                },
            },
            variants: [
                {
                    props: { variant: 'bodyIcon' },
                    style: {
                        padding: '1',
                        width: '32px',
                        cursor: 'pointer',
                    },
                },
                {
                    props: { variant: 'headerIcon' },
                    style: {
                        borderColor: 'black',
                        cursor: 'pointer',
                        paddingRight: '16px',
                    },
                },
            ],
        },
        MuiTooltip: {
            defaultProps: { arrow: false },
            styleOverrides: {
                tooltip: {
                    maxWidth: 300,
                    fontSize: '0.9rem',
                    backgroundColor: '#292c4e',
                    color: 'white',
                    padding: '10px'
                },
            },
        },
    }

})

function win(res) {
    console.log(`success  ${JSON.stringify(res)}`)
}

function fail(res) {
    console.log(`fail ${JSON.stringify(res)}`)
}

function readTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            window.cordova.exec(win, fail, 'idfa', 'getInfo', [])
        }
    }
}

function askTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            console.log('trying to request permission ')
            window.cordova.exec(win, fail, 'idfa', 'requestPermission', [])
        }
    }
}

function onDeviceReady() {
    window.open = window.cordova.InAppBrowser.open
}
document.addEventListener('deviceready', onDeviceReady, false)
document.addEventListener('deviceready', readTrackingPermission, false)
document.addEventListener('deviceready', askTrackingPermission, false)

render(
    <ThemeProvider theme={theme}>
        <Provider store={AppStore}>
            <ReduxRouter history={history}>
                <SieauTooltip />
                <Routes>
                    <Route path='login' element={<ComponentWithNavObject Component={Login} />} />
                    <Route path='reset' element={<ComponentWithNavObject Component={Password} />} />
                    <Route path='admin' element={<ComponentWithNavObject Component={Admin} />} />
                    <Route path='/' element={<AuthRoute Component={AppDesktop} />} >
                        <Route path='/home' element={<ComponentWithNavObject Component={HomeDesktop}/>} />

                        <Route path='/message' element={<ComponentWithNavObject Component={MessagesApp}/>} />
                        <Route path='/tutoriel' element={<ComponentWithNavObject Component={TutorielDesktop}/>} />

                        <Route path='/documents' element={<ComponentWithNavObject Component={MyDocumentsDesktop}/>} />

                        <Route path='/dossier'>
                            <Route index element={<ComponentWithNavObject Component={MyExploitationDesktop}/>} />
                            <Route path='point/:id' element={<ComponentWithNavObject Component={PointDetailsExploitDesktop}/>} />
                        </Route>

                        <Route path='/installations'>
                            <Route index element={<ComponentWithNavObject Component={PointsListAppDesktop}/>} />
                            <Route path='point/:id'>
                                <Route index element={<ComponentWithNavObject Component={PointDashboardDesktop}/>} />
                                <Route path='events/materiels' element={<ComponentWithNavObject Component={EventsMaterielsApp}/>} />
                            </Route>
                        </Route>

                        <Route path='index/:id/materiels' element={<ComponentWithNavObject Component={IndexMaterielsApp}/>} />

                        <Route path='/myaccount'>
                            <Route index element={<Navigate replace path='' to='preferences' />} />
                            <Route path='preferences' element={<ComponentWithNavObject Component={MyAccountDesktop}/>} />
                            <Route path='about' element={<ComponentWithNavObject Component={AboutDesktop}/>} />
                        </Route>

                        <Route path='/declaration'>
                            <Route index element={<ComponentWithNavObject Component={Declaration}/>} />
                            <Route path='start' element={<ComponentWithNavObject Component={HomeSurvey}/>} />
                            <Route path='index/:id/materiels' element={<ComponentWithNavObject Component={IndexMaterielsAppDecla}/>} />
                            <Route path='point/:id'>
                                <Route index element={<ComponentWithNavObject Component={PointDetailsDeclaDesktop}/>} />
                                <Route path='usages' element={<ComponentWithNavObject Component={PointUsagesPrevi}/>} />
                            </Route>
                        </Route>

                        <Route path='*' element={<Navigate replace path='' to='home' />} />
                    </Route>
                </Routes>
            </ReduxRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)