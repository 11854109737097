/* eslint-disable camelcase */
import { CircularProgress, Grid, TextField, styled } from '@mui/material'
import React, { useState } from 'react'
import { mainColor, veryLightColor } from './styled/theme'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isNil, orderBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AgriAction from '../agri/actions/AgriAction'
import ToastrAction from './toasters/ToastrAction'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { formatMilliers } from '../../../utils/StringUtil'
import { getEvolValue } from '../../../utils/AgriUtils'
import { DeleteOutlined } from '@mui/icons-material'
import { MainButton } from './styled/buttons'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from './styled/dialog'

const Line = styled(Grid)({
    padding: '16px 8px',
})

const ModalIndexEntry = ({
    material,
    point,
    openModalNewIndex,
    setOpenModalNewIndex,
    setIndexedPoint= () => {},
    getMaterial,
}) => {
    const [measureDate, setMeasureDate] = useState(getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [endDate, setEndDate] = useState('')
    const [value, setValue] = useState('')
    const [chroniclesLoaded, setChroniclesLoaded] = useState(true)

    const {
        exploitation,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
    }), shallowEqual)

    const dispatch = useDispatch()

    const consoType = point.consoType || (point.estim ? CHRONICLES_CONSTANTS.TYPE_ESTIM : CHRONICLES_CONSTANTS.TYPE_INDEX)
    const isEstim = consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM
    const mat = material || getMaterial(point.idMatLastChronicle)

    const addChronicle = () => {
        if (measureDate && !isNil(value)) {
            const link_chronicles = [
                ...exploitation.link_chronicles,
                {
                    matType: 'divers',
                    idMat: mat.id,
                    measureType: consoType,
                    measureDate: new Date(measureDate).getTime(),
                    value,
                    endDate: isEstim ? new Date(endDate).getTime() : undefined,
                    idChronicle: undefined,
                    new: true,
                }
            ]
            const updateExploitation = {
                ...exploitation,
                link_chronicles,
            }
            setChroniclesLoaded(false)
            dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
                ToastrAction.success(i18n.entryValidated)
                dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
            })
            setMeasureDate(isEstim ? endDate : '')
            setValue('')
            setEndDate('')
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    const removeChronicle = (idChronicle) => {
        const link_chronicles = [
            ...exploitation.link_chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...exploitation.link_chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        setChroniclesLoaded(false)
        dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
            ToastrAction.success(i18n.entryDeleted)
            dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
        })
    }

    const filteredChronicles = exploitation.link_chronicles.filter((c) => c.idMat === mat.id && c.mode !== 'd' && c.measureType === consoType)
    const orderedChronicles = orderBy(filteredChronicles, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
    const readingCoefficient = mat?.counter?.readingCoefficient || 1

    const getLabelHistory = () => (<span><b>{i18n.lastInput} :</b> {orderedChronicles.length ? `${formatMilliers(orderedChronicles[0].value)} ${i18n.the} ${moment(orderedChronicles[0].measureDate).format('DD/MM/YYYY')}` : i18n.noHistory}</span>)

    return (
        <DialogMUI open={openModalNewIndex}
            keepMounted
            onClose={() => {
                setOpenModalNewIndex(false)
                setIndexedPoint({})
            }}
        >
            <DialogTitleMUIv2 sx={{ padding: '0 0 1.5vh 0' }}
                onClick={() => {
                    setOpenModalNewIndex(false)
                    setIndexedPoint({})
                }}
            >
                {point.name || i18n.pointPrelevement } - {point.code}
            </DialogTitleMUIv2>
            <DialogContentMUIDesktop style={{ width: '100%' }}>
                <DialogContentTextMUI style={{ alignSelf: 'flex-start', padding: '0 0 1.5vh 0' }}>
                    {mat?.counter ? i18n.counter : i18n.pump} <b>{mat?.reference ?? ''}</b> - {i18n.coefLectureShort} : &nbsp;<b>{readingCoefficient}</b>
                </DialogContentTextMUI>
                <Grid item container flexWrap='nowrap' columnGap='8px'>
                    <TextField
                        id='startDate'
                        label={isEstim ? i18n.startDate : i18n.statementDate}
                        type='date'
                        required
                        variant='outlined'
                        value={measureDate}
                        inputProps={{
                            max: endDate || '9999-12-31'
                        }}
                        onChange={(e) => setMeasureDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: '100%' }}
                    />
                    {!!isEstim && (
                        <TextField
                            id='endDate'
                            label={i18n.endDate}
                            type='date'
                            inputProps={{
                                min: measureDate,
                                max: '9999-12-31'
                            }}
                            required
                            variant='outlined'
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%' }}
                        />
                    )}
                </Grid>

                <TextField
                    id='value'
                    label={isEstim ? i18n.estim : i18n.index}
                    type='number'
                    value={value}
                    onChange={(e) => setValue(parseInt(e.target.value))}
                    variant='outlined'
                    sx={{ width: '100%' }}
                    required
                />
                <Grid container sx={{ flexWrap: 'nowrap', alignItems: 'center' }}>
                    <Grid container item justifyContent='flex-start' fontSize={14} sx={{ lineHeight: '20px', }}>
                        {chroniclesLoaded && getLabelHistory()}
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        <MainButton noFullWidth sx={{ width: '6vw' }} disabled={!chroniclesLoaded || isNil(value)} onClick={() => addChronicle()}>
                            {i18n.add}
                        </MainButton>
                    </Grid>
                </Grid>
                <Grid container item justifyContent='flex-start' fontSize={22} sx={{ lineHeight: '28px' }}>{i18n.historical}</Grid>
                <Grid
                    container
                    alignContent='flex-start'
                    justifyContent='center'
                    sx={{ color: mainColor, height: '100%' }}
                >
                    <Line item xs={12} container style={{ padding: '0 8px 16px' }}>
                        {isEstim ? (
                            <>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.startDate }
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.endDate }
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'end' }}>
                                    {i18n.estimateM3 }
                                </Grid>
                                <Grid item className='bold' xs={2} sx={{ textAlign: 'end' }}>
                                    {i18n.accumulationM3}
                                </Grid>
                                <Grid item className='bold' xs={1} sx={{ textAlign: 'end' }} />
                            </>
                        ) : (
                            <>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.statementDate}
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'end' }}>
                                    {i18n.index}
                                </Grid>
                                <Grid item className='bold' xs={4} sx={{ textAlign: 'end' }}>
                                    {`${i18n.evolution} (m3)`}
                                </Grid>
                                <Grid item className='bold' xs={1} sx={{ textAlign: 'end' }}>{' '}</Grid>
                            </>
                        )}
                    </Line>
                    <Grid item className xs={12} sx={{ borderBottom: '1px solid black' }} />
                    <Grid container sx={{ overflowY: 'auto', height: '200px' }} >
                        {chroniclesLoaded ? orderedChronicles.map((chronicle, i) => {
                            const backgroundColor = i % 2 ? veryLightColor : 'white'
                            const valueEvol = (i !== (filteredChronicles.length - 1)) && getEvolValue(consoType, orderedChronicles, chronicle, i)
                            if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                                return (
                                    <Line item xs={12} container>
                                        <Grid item xs={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                            {getDate(chronicle.measureDate, 'DD/MM')}
                                        </Grid>
                                        <Grid item xs={3} sx={{ textAlign: 'start' }}>
                                            {chronicle.endDate ? getDate(chronicle.endDate, 'DD/MM') : ''}
                                        </Grid>
                                        <Grid item xs={3} sx={{ textAlign: 'end' }}>
                                            {`${formatMilliers(chronicle.value) || 0} m3`}
                                        </Grid>
                                        <Grid item xs={2} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                            {!isNil(valueEvol) ? formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0)) : ''}
                                        </Grid>
                                        <Grid item xs={1} container justifyContent='flex-end'>
                                            <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                        </Grid>
                                    </Line>
                                )
                            }
                            return (
                                <Line item xs={12} container alignItems='center' sx={{ backgroundColor }}>
                                    <Grid item xs={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                        {getDate(chronicle.measureDate)}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: 'end' }}>
                                        {formatMilliers(chronicle.value) || 0}
                                    </Grid>
                                    <Grid item xs={4} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {!isNil(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                    </Grid>
                                    <Grid item xs={2} container justifyContent='flex-end'>
                                        <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                    </Grid>
                                </Line>
                            )
                        }) : <Grid item xs={12} container justifyContent='center' sx={{ paddingTop: '16px' }}><CircularProgress /></Grid>}
                    </Grid>
                </Grid>
            </DialogContentMUIDesktop>
        </DialogMUI>
    )
}

ModalIndexEntry.propTypes = {
    material: PropTypes.instanceOf(PropTypes.shape({})),
    point: PropTypes.instanceOf(PropTypes.shape({})),
    openModalNewIndex: PropTypes.bool,
    setOpenModalNewIndex: PropTypes.func,
    setIndexedPoint: PropTypes.func,
    getMaterial: PropTypes.func,
}

export default ModalIndexEntry