import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Divider, Icon, useTheme } from '@mui/material'
import { CMS_PATH } from '../conf/basepath'
import { orderBy } from 'lodash'
import { sieauTooltip } from '../utils/FormUtils'
import { CancelOutlined } from '@mui/icons-material'
import { darkTextColor, mainColor } from './online/components/styled/theme'
import useApplicationSetting from '../utils/customHooks/useApplicationSetting'
import { CMS_STATUS } from './online/cms/constants/CmsConstants'

const Tutoriel = () => {
    const {
        cmsEvents
    } = useSelector((store) => ({
        cmsEvents: store.CmsReducer.cmsEvents
    }))

    const theme = useTheme()

    const tutoCategId = useApplicationSetting('iryquaCategorieCmsTuto', v => Number(v)) || 4

    const tutos = useMemo(() => orderBy(cmsEvents, 'id', 'desc').filter((a) => a.status === CMS_STATUS.PUBLISHED && a.idCategory === tutoCategId), [cmsEvents, tutoCategId])

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const openWebSite = (link) => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    return (
        <Grid container item xs={12} sx={{ maxHeight: '100%', overflow: 'hidden' }}>
            {tutos.length ?
                <Grid container item sx={{ height: '100%', overflowY: 'auto' }}>
                    {tutos.map((c) => {
                        return (
                            <Grid key={c.id} item xs={12} sx={{ color: mainColor, padding: theme.spacing(2) }}>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }}>
                                        {c.title}
                                    </Grid>
                                    {!!c.document.length && (
                                        <Icon
                                            className='clickable'
                                            onClick={() => openDocument(c.document[0])}
                                            fontSize='large'
                                            {...sieauTooltip(i18n.openAttachedDoc)}
                                        >
                                                insert_drive_file
                                        </Icon>
                                    )}
                                </Grid>
                                <Grid style={{ backgroundColor: 'white', padding: theme.spacing(4), borderRadius: 10, border: '1px solid', fontSize: '14px', lineHeight: '20px' }} dangerouslySetInnerHTML={{ __html: c.comment || '' }} />
                                <p className='blue-text clickable' onClick={() => openWebSite(c.link)}>{c.link}</p>
                                <Divider style={{ margin: '20px 0' }} />
                            </Grid>
                        )
                    })}
                </Grid>
                :
                (
                    <Grid container direction='column' alignItems='center' justifyContent='center'>
                        <CancelOutlined sx={{ fontSize: '80px', color: mainColor }}/>
                        <Grid item sx={{ fontSize: '24px', color: darkTextColor, lineHeight: '32px', textAlign: 'center', padding: `${theme.spacing(4)} ${theme.spacing(12)}` }}>
                            {i18n.noTutoText}
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default Tutoriel
