import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { hasValue } from '../../../utils/NumberUtil'
import { darkRed, darkTextColor, otherBlue, textColor } from '../components/styled/theme'
import HomeAction from '../../offline/actions/HomeAction'
import LoadingCard from '../components/cards/LoadingCard'
import { isNil, orderBy, template } from 'lodash'
import moment from 'moment'
import { Cancel, Error, KeyboardArrowLeft } from '@mui/icons-material'
import { RedButton } from '../components/styled/buttons'
import { convertToRGB } from '../../../utils/ColorUtil'
import { formatDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import { LightCard } from '../components/styled/grid'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import ModalIndexEntry from '../components/ModalIndexEntry'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { useParams } from 'react-router'

const IndexMaterielsApp = () => {
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)
    const [indexedMaterial, setIndexedMaterial] = useState({})

    const params = useParams()

    const dispatch = useDispatch()

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const idInstallation = useMemo(() => Number(params.id), [params])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        variousMatTypes,
        codesSandre,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])


    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: `${i18n.pointMateriels} ${installation.code ?? ''}`,
            href: `/index/${idInstallation}/materiels`,
        }]))
    }, [installation, idInstallation])

    const { pumpsIds, countersIds, materiels } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        return {
            pumpsIds: idsPumps,
            countersIds: idsCounters,
            materiels: variousMateriels.filter((m) => [...idsPumps, ...idsCounters].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)),
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    const lastIndexSince = useMemo(() => {
        const lastChronicle = orderBy(exploitation?.link_chronicles?.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === 2) || [], 'measureDate', 'desc')[0]
        if (lastChronicle) {
            return moment().diff(moment(lastChronicle.measureDate), 'days')
        }
        return 0
    }, [pumpsIds, countersIds, idInstallation])


    const lastChronicle = useMemo(() => {
        const pointChronicles = exploitation?.link_chronicles?.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!installation.startDate || measureDate >= installation.startDate) &&
            (!installation.endDate || measureDate < installation.endDate)
        ) || []
        const last = pointChronicles.length ? pointChronicles.reduce((acc, chronique) => (acc.measureDate > chronique.measureDate) ? acc : chronique) : null
        if (last && last.measureType !== 1) {
            return (
                <Grid
                    item
                    sx={{
                        border: `1px solid ${otherBlue}`,
                        borderRadius: '8px',
                        padding: '6px 12px',
                        fontSize: '12px',
                        color: otherBlue,
                        width: 'fit-content',
                        marginBottom: '16px',
                    }}
                >
                    {`${i18n.lastIndexEntryAt}${formatDate(last.measureDate)} : ${formatMilliers(last.value) || 0}`}
                </Grid>
            )
        }
        return null
    }, [])

    if (!pointsLoaded) {
        return <LoadingCard />
    }

    const getMaterials = () => (
        orderBy(materiels, ['counter', 'pump', 'reference']).map((m) => {
            const matType = variousMatTypes.find((t) => t.materielType === m.materielType)
            const isPump = !isNil(m?.pump)
            const matCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === m.mobilityCode)
            return (
                <Grid
                    item
                    container
                    alignItems='stretch'
                    xs={3}
                >
                    <LightCard
                        key={m.id}
                        container
                        item
                        direction='column'
                        className='clickable'
                        sx={{ height: 'auto' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIndexedMaterial(m)
                            setOpenModalNewIndex(true)
                        }}
                    >
                        <Grid item container justifyContent='space-between' sx={{ fontSize: '24px', fontWeight: 'medium' }}>
                            <Grid item>
                                {m.counter ? (
                                    <>
                                        <CounterLogo style={{ marginRight: '10px' }} />
                                        {i18n.counter}
                                    </>
                                ) : (
                                    <>
                                        <PumpLogo style={{ marginRight: '10px' }} />
                                        {i18n.pump}
                                    </>
                                )}
                            </Grid>
                            <Grid item>{m?.reference ?? ''}</Grid>
                        </Grid>
                        {m?.name && <Grid item className='bold' fontSize={14} sx={{ marginTop: '8px' }}>{m.name}</Grid>}
                        <Grid item container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                            <Grid item>{isPump ? i18n.nature : i18n.type}</Grid>
                            <Grid item className='bold'>{matType ? matType.name : '-'}</Grid>
                        </Grid>
                        {isPump ? (
                            <>
                                <Grid item container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    <Grid item>{i18n.categ}</Grid>
                                    <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                </Grid>
                                <Grid item container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    <Grid item>{i18n.debitFonctionnement}</Grid>
                                    <Grid item className='bold'>{m?.pump?.operatingFlow ?? '-'}</Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    <Grid item>{i18n.mobility}</Grid>
                                    <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                </Grid>
                            </>
                        )}
                    </LightCard>
                </Grid>
            )
        })
    )

    if (getMaterials().length) {
        return (
            <Grid
                container
                direction='column'
                item
                xs={12}
                sx={{
                    padding: '5vh',
                    maxHeight: 'calc(100% - 4vh)',
                }}
            >
                <Grid container item paddingBottom='2.5vh' justifyContent='flex-start' color={textColor}>
                    <Grid item
                        sx={{ paddingRight: '1.5vw' }}
                        className='clickable'
                        onClick={() => window.history.back()}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.selectConcernedMaterial}</Grid>
                </Grid>
                {lastIndexSince >= (365 * 3) ? (
                    <RedButton
                        startIcon={<Error />}
                        noFullWidth={true}
                        sx={{
                            minHeight: '32px',
                            marginTop: 0,
                            fontSize: '12px',
                            marginBottom: '16px',
                            color: darkRed,
                            backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                            border: `1px solid ${darkRed}`,
                            borderRadius: '8px',
                            '&:disabled': {
                                color: darkRed,
                                backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                                border: `1px solid ${darkRed}`,
                                borderRadius: '8px',
                            },
                        }}
                        disabled
                    >
                        {template(i18n.noIndexSinceXDaysShort)({ days: lastIndexSince })}
                    </RedButton>
                ) : lastChronicle}
                <Grid container spacing={2} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                    {getMaterials()}
                </Grid>
                {openModalNewIndex && <ModalIndexEntry
                    point={{ ...installation, consoType: materielsTypeForIndex.includes(indexedMaterial.materielType) ? CHRONICLES_CONSTANTS.TYPE_INDEX : CHRONICLES_CONSTANTS.TYPE_ESTIM }}
                    openModalNewIndex={openModalNewIndex}
                    setOpenModalNewIndex={setOpenModalNewIndex}
                    material= {indexedMaterial}
                />}
            </Grid>
        )
    }
    return (
        <Grid container item sx={{ height: 'calc(100% - 2.5vh - 28px)', alignContent: 'center' }}>
            <Grid container item xs={12} sx={{ color: darkTextColor, paddingBottom: '12px', justifyContent: 'center' }}><Cancel sx={{ fontSize: '70px' }}/></Grid>
            <Grid container item xs={12} sx={{ fontSize: '24px', color: darkTextColor, justifyContent: 'center' }} >{i18n.noMaterials}</Grid>
        </Grid>
    )
}

IndexMaterielsApp.propTypes = {
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default IndexMaterielsApp
