import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Close, Done, EditOutlined } from '@mui/icons-material'
import { LightCard } from '../styled/grid'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'
import LoadingCard from './LoadingCard'
import { Input } from '../styled/inputs'

const PumpCardDesktop = ({ pump, noLightCard, sx, onClick = () => {}, setPump, statePump, isLoading, hideIcon }) => {
    const {
        variousMatTypes,
        codesSandre,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const pumpNature = useMemo(() => variousMatTypes.find((t) => t.materielType === pump.materielType), [])
    const pumpCategory = useMemo(() => codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pump.mobilityCode), [])

    const content = (
        <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
            <Grid item container justifyContent='space-between'>
                <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{`${i18n.pump} ${pump.reference}`}</Grid>
                {statePump ?
                    pump.id !== statePump.id ?
                        null
                        :
                        (
                            <Grid item>
                                <Close className='clickable' onClick={() => setPump(null)}/>
                                <Done className='clickable' onClick={onClick} />
                            </Grid>
                        )
                    :
                    hideIcon ?
                        null
                        :
                        (<EditOutlined className='clickable' onClick={() => setPump(pump)}/>)
                }
            </Grid>
            {isLoading ?
                <LoadingCard />
                :
                <>
                    <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                        <Grid item>{i18n.name}</Grid>
                        {
                            pump.id !== statePump?.id ?
                                (<Grid item className='bold'>{pump?.name || '-'}</Grid>)
                                :
                                (<Input
                                    value={statePump?.name}
                                    onKeyDown={(e) => e.key === 'Enter' ? onClick() : null}
                                    onChange={(e) => setPump({ ...statePump, name: e.target.value })}
                                    variant='standard'
                                    style={{ width: '50%' }}
                                    inputProps={{ sx: { padding: '0 5px', height: '19px' } }}
                                />)
                        }
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                        <Grid item>{i18n.nature}</Grid>
                        <Grid item className='bold'>{pumpNature?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                        <Grid item>{i18n.categ}</Grid>
                        <Grid item className='bold'>{pumpCategory?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                        <Grid item>{i18n.sharedEquipment}</Grid>
                        <Grid item className='bold'>{pump?.isShared ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                        <Grid item>{i18n.debitMax}</Grid>
                        <Grid item className='bold'>{pump?.pump?.maxFlow ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.debitFonctionnement}</Grid>
                        <Grid item className='bold'>{pump?.pump?.operatingFlow ?? '-'}</Grid>
                    </Grid>
                </>
            }
        </Grid>
    )

    if (noLightCard) {
        return content
    }

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            {content}
        </LightCard>
    )
}

PumpCardDesktop.propTypes = {
    pump: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    statePump: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    isLoading: PropTypes.bool.isRequired,
    setPump: PropTypes.func.isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
    onClick: PropTypes.func,
    hideIcon: PropTypes.bool.isRequired,
}

export default PumpCardDesktop
